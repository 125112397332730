<template>
  <v-container>
    <h3>Inputs</h3>
    <v-row>
      <v-col sm="4">
        <v-select
          v-model="market"
          label="Market"
          :items="marketItems"
          :rules="[(v) => !!v || 'required']"
        />
      </v-col>
      <v-col sm="4" v-if="market !== 'csv'">
        <v-select
          v-model="value.input.region"
          label="Region"
          :items="getRegions(market)"
          :disabled="market !== 'pjm'"
        />
      </v-col>
      <v-col sm="4" v-if="market !== 'csv'">
        <v-text-field
          v-model="value.input.node_name"
          label="Node"
          :rules="[(v) => !!v || 'required']"
        />
      </v-col>
    </v-row>
    <v-row v-if="market === 'csv'">
      <v-col sm="3">
        <InputCSV
          :on-complete="saveEnergyPrice"
          use-headers
          @file-selected="setCSVName($event, 'energyCSVName')"
        >
          <template #activator="{ triggerUpload }">
            <v-text-field
              v-model="energyCSVName"
              label="Energy Prices"
              placeholder="Upload csv file"
              readonly
              append-icon="publish"
              @click="triggerUpload"
              @click:append="triggerUpload"
            >
            </v-text-field>
          </template>
        </InputCSV>
      </v-col>
      <v-col sm="3">
        <InputCSV
          :on-complete="saveAncillaryPrice"
          use-headers
          @file-selected="setCSVName($event, 'ancillaryCSVName')"
        >
          <template #activator="{ triggerUpload }">
            <v-text-field
              v-model="ancillaryCSVName"
              label="Ancillary Prices"
              placeholder="Upload csv file"
              readonly
              append-icon="publish"
              @click="triggerUpload"
              @click:append="triggerUpload"
            >
              <template #append> </template>
            </v-text-field>
          </template>
        </InputCSV>
      </v-col>
      <v-col sm="3">
        <InputCSV
          :on-complete="saveForecastFuel"
          use-headers
          @file-selected="setCSVName($event, 'forecastFuelCSVName')"
        >
          <template #activator="{ triggerUpload }">
            <v-text-field
              v-model="forecastFuelCSVName"
              label="Forecast Fuel"
              placeholder="Upload csv file"
              readonly
              append-icon="publish"
              @click="triggerUpload"
              @click:append="triggerUpload"
            >
              <template #append> </template>
            </v-text-field>
          </template>
        </InputCSV>
      </v-col>
      <v-col sm="3">
        <InputCSV
          :on-complete="saveHistoricalFuel"
          use-headers
          @file-selected="setCSVName($event, 'historicalFuelCSVName')"
        >
          <template #activator="{ triggerUpload }">
            <v-text-field
              v-model="historicalFuelCSVName"
              label="Historical Fuel"
              placeholder="Upload csv file"
              readonly
              append-icon="publish"
              @click="triggerUpload"
              @click:append="triggerUpload"
            >
              <template #append> </template>
            </v-text-field>
          </template>
        </InputCSV>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="value.input.hist_start_year"
          label="Historical Start Year"
          type="number"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="value.input.hist_end_year"
          label="Historical End Year"
          type="number"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="4">
        <v-select
          v-model="value.input.fuel_type"
          label="Fuel Type"
          :items="fuelTypeItems"
          :rules="[(v) => !!v || 'required']"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model.number="value.input.fuel_delivery_charge"
          label="Fuel Delivery Charge"
          type="number"
          :rules="[(v) => !!v || 'required']"
        />
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <h3>Settings</h3>
    <v-row>
      <v-col sm="6">
        <v-radio-group v-model="value.input.profile" row>
          <v-radio label="Hourly" value="H"></v-radio>
          <v-radio label="Sub-Hourly" value="30min"></v-radio>
          <v-radio label="5 Min" value="5min"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="4"
        ><v-text-field
          v-model.number="value.input.start_of_forecast"
          label="Start Year of Forecast"
          type="number"
          :min="1900"
          :rules="forecastStartRules"
      /></v-col>
      <v-col sm="4"
        ><v-text-field
          v-model.number="value.input.end_of_forecast"
          label="End Year of Forecast"
          type="number"
          :min="value.input.start_of_forecast"
          :rules="forecastEndRules"
      /></v-col>
      <v-col sm="4">
        <v-text-field
          v-model.number="value.input.historical_year"
          label="Shaping Year"
          type="number"
          :rules="[(v) => !!v || 'required']"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="4">
        <v-switch v-model="value.input.forecast_limit" label="Forecast Limiter" />
      </v-col>
      <v-col sm="2" class="mr-2">
        <v-text-field
          v-if="value.input.forecast_limit"
          v-model.number="value.input.max_limit"
          label="Max Forecast Limit"
          type="number"
        />
      </v-col>
      <v-col sm="2" class="mx-2">
        <v-text-field
          v-if="value.input.forecast_limit"
          v-model.number="value.input.min_limit"
          label="Min Forecast Limit"
          type="number"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="4">
        <v-switch v-model="toggleHFV" label="High Forecast Value" />
      </v-col>
      <v-col sm="2">
        <v-text-field
          v-if="toggleHFV"
          v-model.number="value.input.hfv"
          label="HFV multiplier"
          type="number"
          :rules="hfvRules"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="4">
        <v-switch v-model="toggleLFV" label="Low Forecast Value" />
      </v-col>
      <v-col sm="2">
        <v-text-field
          v-if="toggleLFV"
          v-model.number="value.input.lfv"
          label="LFV multiplier"
          type="number"
          :rules="lfvRules"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "IHRForm",
  components: {
    InputCSV: () => import("@/components/InputCSV"),
  },
  mixins: [],
  props: { value: { type: Object, required: true } },
  data() {
    return {
      toggleHFV: false,
      toggleLFV: false,
      energyCSVName: "",
      forecastFuelCSVName: "",
      ancillaryCSVName: "",
      historicalFuelCSVName: "",
      marketItems: [
        { text: "PJM", value: "pjm" },
        { text: "ERCOT", value: "ercot" },
        { text: "Custom", value: "csv" },
      ],
      profileItems: [
        { text: "Hourly", value: "H" },
        { text: "Sub-hourly", value: "30min" },
      ],
      fuelTypeItems: [
        { text: "Natural Gas", value: "NG" },
        { text: "Diesel", value: "Diesel" },
      ],
      forecastStartRules: [
        (v) => !!v || "required",
        (v) => v >= 1900 || "Should be greater than 1900",
      ],
      hfvRules: [(v) => (!!v && this.toggleHFV) || "required", (v) => v >= 1 || "Should be >= 1"],
      lfvRules: [(v) => (!!v && this.toggleLFV) || "required", (v) => v <= 1 || "Should be <= 1"],
    };
  },
  computed: {
    forecastStartYear() {
      return this.value.input.start_of_forecast;
    },
    forecastEndRules() {
      return [
        (v) => !!v || "required",
        (v) => v >= 1900 || "Should be greater than 1900",
        (v) =>
          v >= this.value.input.start_of_forecast ||
          `Should be grater than ${this.value.input.start_of_forecast}`,
      ];
    },
    market: {
      get() {
        return this.value.input.market_name;
      },
      set(val) {
        this.value.input.market_name = val;
        if (val === "ercot") {
          this.value.input.region = "none";
        }
        if (val === "csv") {
          this.value.input.price_data = {
            energy_prices: [],
            ancillary_prices: [],
            forecast_fuel: [],
            historical_fuel: [],
          };
        } else if (val !== "csv" && this.value.input.price_data) {
          delete this.value.input.price_data;
          this.energyCSVName = "";
          this.forecastFuelCSVName = "";
          this.ancillaryCSVName = "";
          this.historicalFuelCSVName = "";
        }
        this.$emit("input", this.value);
      },
    },
  },
  watch: {
    forecastStartYear(val) {
      if (!this.value.input.end_of_forecast && val >= 1900) {
        this.value.input.end_of_forecast = val;
      }
    },
    toggleHFV(val) {
      if (val) {
        this.value.input.hfv = 1;
      } else {
        this.value.input.hfv = -1;
      }
    },
    toggleLFV(val) {
      if (val) {
        this.value.input.lfv = 1;
      } else {
        this.value.input.lfv = -1;
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    saveEnergyPrice(results) {
      this.value.input.price_data.energy_prices = results;
    },
    saveAncillaryPrice(results) {
      this.value.input.price_data.ancillary_prices = results;
    },
    saveForecastFuel(results) {
      this.value.input.price_data.forecast_fuel = results;
    },
    saveHistoricalFuel(results) {
      this.value.input.price_data.historical_fuel = results;
    },
    setCSVName($event, name) {
      this[name] = $event.name;
    },
    getRegions(market) {
      const regions = {
        pjm: [
          { text: "MAD", value: "mad" },
          { text: "RTO", value: "rto" },
        ],
        ercot: [""],
      };
      return regions[market];
    },
  },
};
</script>

<style></style>
